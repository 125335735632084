<script setup lang="ts">
const nuxtApp = useNuxtApp();
const { activeHeadings, updateHeadings } = useScrollspy();

const links = computed(() => [
  {
    label: 'Celebração',
    to: '#celebration',
    icon: 'i-heroicons-question-mark-circle',
    active: activeHeadings.value.includes('celebration'),
  },
  {
    label: 'Pequenos Grupos',
    to: '#pgs',
    icon: 'i-heroicons-cube-transparent',
    active: activeHeadings.value.includes('pgs'),
  },
]);

nuxtApp.hooks.hookOnce('page:finish', () => {
  updateHeadings([
    document.querySelector('#celebration')!,
    document.querySelector('#pgs')!,
  ]);
});
</script>

<template>
  <AppHeader :links="links">
    <template #logo>
      <AppLogoText />
    </template>

    <template #panel>
      <AppAsideLinks :links="links" />
    </template>
  </AppHeader>
</template>